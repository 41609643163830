<template>
  <v-card color="primary" class="ma-auto" outlined width="500" max-width="500">
    <v-card-title class="white--text">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-chart-line</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Data Selection</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-dialog
                ref="startdialog"
                v-model="startmodal"
                :return-value.sync="startdate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startdate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startdate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startmodal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startdialog.save(startdate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-dialog
                ref="enddialog"
                v-model="endmodal"
                :return-value.sync="enddate"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="enddate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="enddate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="endmodal = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.enddialog.save(enddate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-list-item>
            <v-list-item>
              <v-select
                v-model="datatoshow"
                :items="possibledata"
                attach
                chips
                label="Data to Plot"
                multiple
              ></v-select>
            </v-list-item>
          </v-list>

          <v-list>
            <template v-for="(item, i) in datatoshow">
              <v-list-item :key="i">
                <v-list-item-action>
                  <v-switch v-model="message" color="purple"></v-switch>
                </v-list-item-action>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text @click="menu = false"> Cancel </v-btn>
            <v-btn color="primary" text @click="menu = false"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      Thermocouple Data
      <v-flex class="text-right">
        <v-btn icon dark @click="reload">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-flex>
    </v-card-title>

    <vue-plotly :data="xydata" :layout="xylayout" />
  </v-card>
</template>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

<script>
import VuePlotly from "@statnett/vue-plotly";
import measurements from "@/api/measurements.js";
var moment = require("moment");

export default {
  components: {
    VuePlotly,
  },

  props: {
      showlist: Array
  },

  data: () => ({
    xylayout: {
      filename: "basic-line",
      fileopt: "overwrite",
      showlegend: false,
      xaxis: {
        title: "Time",
        ticks: "outside",
        ticklen: 8,
        tickwidth: 2,
        tickcolor: "#000",
        tickformat: "%H:%M:%S\n%b %d",
        //ticklabelmode: "period",
      },
      yaxis: {
        title: "Temperature [C]",
        //title: "Diff Press [mil-H2O]",
        ticks: "outside",
        ticklen: 8,
        tickwidth: 2,
        tickcolor: "#000",
      },
      margin: {
        l: 60,
        r: 20,
        //b: 70,
        t: 30,
        pad: 4,
      },
    },
    xyoptions: {
      displaylogo: false,
    },
    bookingReloadKey: 0,
    startmodal: false,
    endmodal: false,
    enddate: moment().endOf("day").format("YYYY-MM-DD"),
    startdate: moment().subtract(3, "days").format("YYYY-MM-DD"),
    mapToAPI: {
      "Oa.Tc": { apiname: "jorgtc", dataname: "temperature" },
      "39-4": { apiname: "trl", dataname: "temperature", usedecay: 0.98 },
      "J.Rh": { apiname: "jorgtest2", dataname: "humidity" },
      "J.Temp": { apiname: "jorgtest2", dataname: "temperature" },
      "JH.Temp": { apiname: "jorghome", dataname: "temperature" },
      "JH.Rh": { apiname: "jorghome", dataname: "humidity" },
      "J.DP": { apiname: "jorghome", dataname: "fanspeed", sub: ["dp","pressure"] },
      "J.DuctP": { apiname: "jorghome", dataname: "fanspeed", sub: ["duct","pressure"] },
      "J.Part": {
        apiname: "jorghome2",
        dataname: "particles",
        sub: ["mass", "pm1p0"],
      },
      "J.PartOut": {
        apiname: "jorghome2",
        dataname: "particlesOutside",
        sub: ["mass", "pm1p0"],
      },
      "JH.TC": {
        apiname: "jorghome",
        dataname: "system",
        sub: ["tc", "temperature"],
      },
      "JT.GHouse": {
        apiname: "jorghome2",
        dataname: "greenhouse",
        sub: ["temperature"],
      },
      "JT.Inside": {
        apiname: "jorghome2",
        dataname: "inside",
        sub: ["temperature"],
      },      
        "JH.CO2": {
        apiname: "jorghome2",
        dataname: "co2",
        sub: ["CO2"],
      },
      "JH.Ay": {
        apiname: "jorghome",
        dataname: "system",
        sub: ["acceleration", "Ay"],
      },
      "JH.Az": {
        apiname: "jorghome",
        dataname: "system",
        sub: ["acceleration", "Az"],
      },                  
    Fan: {
        apiname: "jorghome",
        dataname: "fanspeed",
        sub: ["f1"],
        multiplier: 0.001,
      },
    },
    possibledata: [
      "Oa.Tc",
      "Fan",
      "J.Rh",
      "J.Temp",
      "J.DP",
      "J.DuctP",
      "JT.GHouse",
      "JT.Inside",
      "J.Part",
      "J.PartOut",
      "JH.Temp",
      "JH.Rh",
      "JH.TC",
      "JH.CO2",
      "JH.Ay",
      "JH.Az",
    ],
    datatoshow: ["J.Part", "JH.Temp", "Fan", "J.PartOut","J.DuctP","JT.GHouse","JT.Inside"],
    colorlist: ["#ffc107", "#f44336", "#9c27b0", "#03a9f4", "#009688","#009688","#a09688","#f0f000"],
  }),

  asyncComputed: {
    xydata: {
      async get() {
        var alldata = [];
        var pastResult = {};
        for (var m = 0; m < this.datatoshow.length; m++) {
          var lineColor = this.colorlist[m % this.colorlist.length];
          var params = {
            start: moment(this.startdate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            end: moment(this.enddate)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss"),
            name: this.mapToAPI[this.datatoshow[m]].apiname,
          };
          var results = pastResult[params.name];
          if (results === undefined) {
            results = await measurements.loadData(params);
            if (results.error) {
              // some issue...
              console.log("Error loading data");
              return null;
            }
            results = results.data;
            pastResult[params.name] = results;
          }
          // extract mean temperature into xy plot
          var xy = {
            x: [],
            y: [],
            type: "line",
            name: "TC1",
            line: {
              color: lineColor,
              width: 2,
            },
          };

          var smoothValue = 0;
          var decay = 0;
          var multiplier = 1;
          if (this.mapToAPI[this.datatoshow[m]].multiplier !== undefined)
            multiplier = this.mapToAPI[this.datatoshow[m]].multiplier;
          if (this.mapToAPI[this.datatoshow[m]].usedecay !== undefined)
            decay = this.mapToAPI[this.datatoshow[m]].usedecay;
          for (var k = 0; k < results.length; k++) {
            if (k + 1 == results.length)
              console.log(JSON.stringify(results[0]));
            try {
              var dataSet =
                results[k][this.mapToAPI[this.datatoshow[m]].dataname];
              if (dataSet !== undefined) {
                xy.x.push(moment(results[k].start).format());
                if (this.mapToAPI[this.datatoshow[m]].sub !== undefined) {
                  for (
                    var p = 0;
                    p < this.mapToAPI[this.datatoshow[m]].sub.length;
                    p++
                  ) {
                    dataSet = dataSet[this.mapToAPI[this.datatoshow[m]].sub[p]];
                  }
                  if (k == 0) console.log(JSON.stringify(dataSet));
                }
                const newDatapoint = dataSet.mean * multiplier;
                if (k >= 0)
                  smoothValue =
                    smoothValue * decay + (1 - decay) * newDatapoint;
                else {
                  smoothValue = newDatapoint;
                  console.log(results[k].start);
                }
                //xy[0].y.push(results[k].pressure.mean);
                //xy[0].y.push(dp);
                xy.y.push(smoothValue);
              }
            } catch (ex) {
              console.log("ER1");
              //console.log(JSON.stringify(results[k]));
              //console.log(ex.toString)
            }
          }
          alldata.push(xy);
        }
        return alldata;
      },
      default: [],
    },
  },

  methods: {
    reload() {
      console.log("A");
    },
  },
};
</script>


