var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-auto",attrs:{"color":"primary","outlined":"","width":"500","max-width":"500"}},[_c('v-card-title',{staticClass:"white--text"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-chart-line")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Data Selection")])],1)],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',[_c('v-dialog',{ref:"startdialog",attrs:{"return-value":_vm.startdate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.startdate=$event},"update:return-value":function($event){_vm.startdate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startmodal),callback:function ($$v) {_vm.startmodal=$$v},expression:"startmodal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startmodal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.startdialog.save(_vm.startdate)}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"enddialog",attrs:{"return-value":_vm.enddate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.enddate=$event},"update:return-value":function($event){_vm.enddate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endmodal),callback:function ($$v) {_vm.endmodal=$$v},expression:"endmodal"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.endmodal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.enddialog.save(_vm.enddate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-list-item',[_c('v-select',{attrs:{"items":_vm.possibledata,"attach":"","chips":"","label":"Data to Plot","multiple":""},model:{value:(_vm.datatoshow),callback:function ($$v) {_vm.datatoshow=$$v},expression:"datatoshow"}})],1)],1),_c('v-list',[_vm._l((_vm.datatoshow),function(item,i){return [_c('v-list-item',{key:i},[_c('v-list-item-action',[_c('v-switch',{attrs:{"color":"purple"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)]})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Save ")])],1)],1)],1),_vm._v(" Thermocouple Data "),_c('v-flex',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)],1),_c('vue-plotly',{attrs:{"data":_vm.xydata,"layout":_vm.xylayout}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }