<template>
  <v-card color="primary" class="ma-auto" outlined width="500" max-width="500">
    <v-card-title class="white--text"
      >Interlock Status<v-spacer></v-spacer>
      <v-btn color="white" icon @click="refreshCard()">
        <v-icon>mdi-reload</v-icon>
      </v-btn></v-card-title
    >

    <v-list>
      <v-subheader>
        These items show the channels and their CORAL setup. Use the actions to
        manually override the interlock (hand symbol).
      </v-subheader>
      <template v-for="(item, i) in actionList">
        <v-list-item :key="i">
          <v-list-item-avatar>
            <v-icon :class="item.color" dark> {{ item.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>            
            <v-list-item-title
              v-text="getDescription(item)"
            ></v-list-item-title>
            <v-list-item-subtitle v-text="item.detail"></v-list-item-subtitle>
            <v-list-item-subtitle
              v-text="getActionDescription(item)"
            ></v-list-item-subtitle>
            <v-list-item-subtitle
              v-text="getOverrideText(item)"
            ></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              v-if="item.canActivate"
              :loading="item.loading"
              :disabled="item.loading"
              color="primary"
              @click="buttonInterlock(item)"
            >
              {{ item.buttonText }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

<script>
var moment = require("moment");
import dataloader from "@/api/dataloader.js";

export default {
  data: () => ({
    stateReloadKey: 0,
    configReloadKey: 0,
    stateTimeout: 0.5,
    apiTimeout: 10,
  }),

  props: {
    selectionGroup: String,
  },

  methods: {
    refreshCard() {
      this.$store.dispatch("data/refreshState");
    },
    getDescription(item) {
      var s = item.coralName;
      return s;
    },
    getOverrideText(item) {
      return item.overrideText;
    },
    getActionDescription(item) {
      var s = "";
      //console.log(JSON.stringify(this.config.configuration[item.coralName]))
      var actions = this.config.configuration[item.coralName].actions;
      for (var entry of actions) {
        if (entry.switch !== undefined) {
          s = s + "(";
          if (entry.invert) s = s + "open ";
          else s = s + "close ";
          s = s + entry.switch;
          if (entry.requires > 1) s = s + " if " + entry.requires;
          s = s + ") ";
        }
      }
      return s;
    },
    isEngaged(item) {
      return item.value == 1;
    },
    buttonInterlock(item) {
      item.loading = true;
      // call the API
      if (item.value == 0) {
        dataloader.engage(item.coralName);
      } else {
        dataloader.disengage(item.coralName);
      }
      //var self = this
      setTimeout(() => {
        this.$store.dispatch("data/refreshState");
      }, this.stateTimeout * 1000);
    },
  },

  asyncComputed: {
    config: {
      async get() {
        try {
          // get the setup data
          console.log("Get config");
          //var config = await dataloader.getConfig(this.configReloadKey);
          const config = await this.$store.getters["data/getDataList"](
            "config"
          );
          console.log("Get config done");
          if (config.error) {
            console.log("Error loading data");
            return null;
          }
          if (config.data === undefined) return [];
          return config.data;
        } catch (ex) {
          console.log("error 1");
          return [];
        }
      },
      default: [],
    },
    state: {
      async get() {
        // get the setup data
        try {
          console.log("Get state");
          //var state = await dataloader.getState(this.stateReloadKey);
          const state = await this.$store.getters["data/getDataList"]("state");
          console.log("Get state done");
          if (state.error) {
            console.log("Error loading data");
            return null;
          }
          if (state.data === undefined) return [];
          return state.data;
        } catch (ex) {
          console.log("error 1");
          return [];
        }
      },
      default: [],
    },
    actionList: {
      async get() {
        // get the setup data
        var myState = await this.state;
        var myConfig = await this.config;
        //console.log(JSON.stringify(myState));
        //console.log(JSON.stringify(myConfig));
        if (myState == []) {
          console.log("waiting for data");
          return;
        }
        console.log("Get coral status: ");
        var channelStatus = myState.state.interlock;
        var channelOverride = myState.state.override;
        if (channelOverride !== undefined)
          channelOverride = channelOverride.bytool;
        if (channelOverride === undefined) channelOverride = {};
        var channelSetup = myConfig.configuration;
        var permissions = this.$store.getters["user/permissions"];
        const timeNow = moment().valueOf();
        var results = [];
        for (const [coralName, coralConfig] of Object.entries(channelSetup)) {
          // set up default entry (not engaged)
          var entry = {
            color: "red lighten-1",
            icon: "mdi-minus",
            loading: false,
            detail: coralConfig.detail,
            coralName: coralName,
            value: 0,
            canActivate: permissions.includes("engage"),
            buttonText: "Engage",
          };
          if (
            channelStatus[coralName] !== undefined &&
            channelStatus[coralName].value === "on"
          ) {
            // if engaged
            entry.value = 1;
            entry.icon = "mdi-check";
          }
          // check override
          if (channelOverride[coralName] !== undefined) {
            console.log("A");
            console.log(JSON.stringify(channelOverride[coralName]));
            console.log(JSON.stringify(channelStatus));

            // for the override check, keep everything in terms 0/1 value
            // start by taking the CORAL state value
            var coralActualValue = entry.value; // this was the no-override calculated above
            var overrideValue = 0;
            if (channelOverride[coralName].value == "on") overrideValue = 1;

            if (
              channelOverride[coralName].expires > timeNow &&
              overrideValue !== coralActualValue
            ) {
              entry.value = overrideValue;
              entry.icon = "mdi-hand-back-left";
              entry.overrideText =
                "manual override: " +
                moment(channelOverride[coralName].action).format(
                  "MMM DD HH:mm"
                );
            } else if (channelOverride[coralName].reverted !== undefined) {
              entry.overrideText =
                "override reverted: " +
                moment(channelOverride[coralName].reverted).format(
                  "MMM DD HH:mm"
                );
            }
            if (
              overrideValue == coralActualValue &&
              channelOverride[coralName].action !== undefined
            ) {
              entry.overrideText =
                "override revoked: " +
                moment(channelOverride[coralName].action).format(
                  "MMM DD HH:mm"
                );
            }
          } else if (channelStatus[coralName] !== undefined) {
            if (channelStatus[coralName].action !== undefined) {
              entry.overrideText =
                "coral action: " +
                moment(channelStatus[coralName].action).format("MMM DD HH:mm");
            } else if (channelStatus[coralName].reverted !== undefined) {
              entry.overrideText =
                "auto-disengaged: " +
                moment(channelStatus[coralName].reverted).format(
                  "MMM DD HH:mm"
                );
            }
          }
          if (entry.value === 1) {
            entry.buttonText = "Disengage";
            entry.color = "green lighten-1";
          }
          results.push(entry);
        }
        console.log("BA");
        console.log(JSON.stringify(results));
        return results;
      },
      default: [],
    },
  },
};
</script>