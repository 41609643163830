/*********************************************
 * 
 *  All relevant code to connect to data server
 * 
 *  No other part of the code will access the data API. This way, all URLs are collected
 *  and abstracted out in this module.
 * 
 */

import http from "@/api/http-data";
//var moment = require('moment');

var measurement = {};

measurement.loadData = async function (requestParams) {
    // requestParams is:  start, end, name, dimensions(optional)
    console.log("get data...")
    return (await http.post('/rht/read', requestParams)).data;
}

measurement.getLast = async function (name) {
    return (await http.get('/rht/'+name+'/last')).data;
}

export default measurement;

