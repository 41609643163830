<template v-if="this.$store.getters['user/permissions'].includes('relays')">
  <v-card color="primary" class="ma-auto" outlined width="500" max-width="500">
    <v-card-title class="white--text"
      >System Monitors<v-spacer></v-spacer>
      <v-btn color="white" icon @click="refreshCard()">
        <v-icon>mdi-reload</v-icon>
      </v-btn></v-card-title
    >

    <v-list>
      <v-subheader>
        Snapshot of the data collected by the interlock.
      </v-subheader>
      <v-divider></v-divider>
      <v-subheader> Anti-tamper feedback signals </v-subheader>
      <template v-for="(item, i) in feedbackList">
        <v-list-item :key="i">
          <v-list-item-avatar>
            <v-icon :class="item.color" dark>
              {{ item.icon }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              v-text="getDescription(item)"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              v-if="item.canActivate"
              :loading="item.loading"
              :disabled="item.loading"
              color="primary"
              @click="buttonRelay(item)"
            >
              {{ item.buttonText }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-subheader> System sensor data </v-subheader>
      <template v-for="(item, i) in sensorList">
        <v-list-item :key="i + 1000">
          <v-list-item-avatar>
            <v-icon :class="item.color" dark>
              {{ item.icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="getSensorDescription(item)"
            ></v-list-item-title>
            <v-list-item-subtitle v-text="item.text"></v-list-item-subtitle>
            <v-list-item-subtitle v-text="item.detail"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn v-if="item.hasPlot" fab small @click="plotItem(item)">
              <v-icon color="secondary">mdi-chart-line</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

<script>
//var moment = require("moment");
import dataloader from "@/api/dataloader.js";

export default {
  data: () => ({
    stateReloadKey: 0,
    configReloadKey: 0,
    stateTimeout: 1.0,
    apiTimeout: 10,
    restoreloading: false,
  }),

  props: {
    selectionGroup: String,
  },

  methods: {
    refreshCard() {
      this.$store.dispatch("data/refreshSensors");
    },
    getDescription(item) {
      var s = item.channel + " -- " + item.text;
      return s;
    },
    getSensorDescription(item) {
      var s = item.value + " " + item.units;
      return s;
    },
    isEngaged(item) {
      return item.value == 1;
    },
    buttonRelay(item) {
      item.loading = true;
      // call the API
      console.log(item.value);
      if (item.value == 0) {
        dataloader.setRelay(item.channel, "on");
      } else {
        dataloader.setRelay(item.channel, "off");
      }
      setTimeout(() => {
        this.$store.dispatch("data/refreshState");
        item.loading = false;
      }, this.stateTimeout * 1000);
    },
    buttonRestore() {
      this.restoreloading = true;
      setTimeout(() => {
        this.$store.dispatch("data/refreshState");
        this.restoreloading = false;
      }, this.stateTimeout * 1000);

      dataloader.restoreAll();
    },
  },

  asyncComputed: {
    feedbackList: {
      async get() {
        // get the setup data
        console.log("Get feedback list");
        //var channelSetup = this.config.configuration;
        //var hardwareSetup = this.state.hardware;
        const sensors = this.$store.getters["data/getDataList"]("sensors").data;
        const hardware =
          this.$store.getters["data/getDataList"]("state").data.hardware;
        //var permissions = this.$store.getters["user/permissions"];
        console.log(JSON.stringify(sensors));
        var results = [];
        for (const [key, value] of Object.entries(sensors.feedback)) {
          console.log(key);
          var entry = {
            color: "blue-grey lighten-4",
            icon: "mdi-close",
            text: "not in use",
            channel: key,
          };
          if (hardware.ACRelays[key]) {
            entry.icon = "mdi-check";
            entry.text = "ok";

            if (value.cable == 0) {
              (entry.icon = "mdi-minus-network"),
                (entry.color = "purple accent-2");
              entry.text = "problem: no control cable";
              entry.value = 1;
            } else if (value.power == 0) {
              (entry.icon = "mdi-minus-network"),
                (entry.color = "purple accent-2");
              entry.text = "problem: no power";
              entry.value = 2;
            }
          }
          results.push(entry);
        }
        console.log("Get feedback list done");
        return results;
      },
      default: [],
    },
    sensorList: {
      async get() {
        // get the setup data
        console.log("Get sensor list");
        //var channelSetup = this.config.configuration;
        //var hardwareSetup = this.state.hardware;
        var sensors = this.$store.getters["data/getDataList"]("sensors").data;
        //var permissions = this.$store.getters["user/permissions"];
        var results = [];
        for (const [key, value] of Object.entries(sensors.data)) {
          if (key == "board_temperature") {
            var entry = {
              color: "blue-grey lighten-2",
              icon: "mdi-thermometer",
              text: "board temperature",
              value: String(value),
              units: "C",
              hasPlot: true,
              source: "board.temperature",
            };
            results.push(entry);
          }
        }
        console.log("Get sensor list done");
        return results;
      },
      default: [],
    },
  },
};
</script>