<template>
  <v-card color="primary" class="ma-auto" outlined width="500" max-width="500">
    <v-card-title class="white--text"
      >Event Viewer
      <v-spacer></v-spacer>
      <v-btn color="white" icon @click="refreshCard()">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-card-title>
    <v-list>
      <v-list-item>
        <v-btn-toggle v-model="showTypes" multiple rounded color="primary">
          <template v-for="(item, i) in eventTypes">
            <v-btn :key="i">
              {{ item.name }}
            </v-btn>
          </template>
        </v-btn-toggle>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-list style="max-height: 500px" class="overflow-y-auto">
      <template v-if="eventlist.length == 0">
        <v-list-item>
          <v-list-item-content>There are no events.</v-list-item-content>
        </v-list-item>
      </template>

      <template v-for="(item, i) in eventlist">
        <v-list-item :key="i">
          <v-list-item-avatar>
            <v-icon :class="item.color" dark>
              {{ item.icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="getEventTitle(item)"></v-list-item-title>
            <v-list-item-subtitle
              v-text="getEventDescription(item)"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

<script>
var moment = require("moment");

export default {
  data: () => ({
    bookingReloadKey: 0,
    showTypes: [],
    eventTypes: [
      { type: "coral", name: "Coral" },
      { type: "interlock", name: "Interlock" },
      { type: "relays", name: "Relays" },
      { type: "system", name: "System" },
      { type: "admin", name: "Logins" },
    ],
  }),

  computed: {
    eventTypeList() {
      // get the list of event type names, derived from the eventType object list
      return this.eventTypes.map((el) => el.type);
    },
    eventTypesToShow() {
      // reduce the event type list to only show those selected. if none are selected, show all
      var types_to_show = this.showTypes; // do this because only using .length won't make it update
      if (types_to_show.length == 0) return this.eventTypeList;
      var result = [];
      for (var k = 0; k < this.eventTypeList.length; k++) {
        if (types_to_show.includes(k)) result.push(this.eventTypeList[k]);
      }
      return result;
    },
  },

  asyncComputed: {
    eventlist: {
      async get() {
        console.log("Get event list");
        var event_to_show = this.eventTypesToShow; // do this so it will update
        // get events: counter is a hack to force update of events
        var results = this.processEventList(
          this.$store.getters["data/getDataList"]("events").data.data
        );
        // filter by type
        results = results.filter((el) => event_to_show.includes(el.type));
        console.log("Get event list done");
        return results;
      },
      default: [],
    },
  },

  methods: {
    refreshCard() {
      this.$store.dispatch("data/refreshEventLog");
    },
    processEventList(ev) {
  //    const coralconfig =
    //    this.$store.getters["data/getDataList"]("config").data.configuration;
      //const hardwareSetup =
//        this.$store.getters["data/getDataList"]("state").data.hardware;
      var newEventList = [];
      var detailText = "";
      var newEntry;      
      if (ev.system !== undefined) {
        for (const entry of ev.system) {
          newEntry = { time: entry.time };
          try {
            if (entry.cmd === "startup") {
              newEntry = {
                type: "system",
                title: "Start Interlock",
                icon: "mdi-sync",
                color: "blue-grey darken-1",
                ...newEntry,
              };
            }
            newEventList.push(newEntry);
          } catch {
            console.log("Error");
          }
        }
      }
      if (ev.tamper !== undefined) {
        for (const entry of ev.tamper) {
          newEntry = { time: entry.time };
          try {
            newEntry = {
              type: "system",
              title: "Relay Wire State",
              icon: "mdi-help-network-outline",
              color: "blue-grey darken-1",
              ...newEntry,
            };
            if (entry.initialread === undefined) {
              newEntry.icon = "mdi-minus-network";
              newEntry.title = "Wire Change";
            }
            // figure out the state
            var sDetails = "";
            for (const [key, value] of Object.entries(entry)) {
              if (value.cable !== undefined) {
                if (value.cable === 0) {
                  if (sDetails !== "") sDetails = sDetails + "; ";
                  sDetails = sDetails + key + ": no control cable";
                } else if (value.power === 0) {
                  if (sDetails !== "") sDetails = sDetails + "; ";
                  sDetails = sDetails + key + ": no power ";
                }
              }
            }
            if (sDetails === "") {
              sDetails = "All Good.";
            } else {
              newEntry.color = "purple accent-2";
            }
            newEntry.details = sDetails;
            newEventList.push(newEntry);
          } catch {
            console.log("Error");
          }
        }
      }
      if (ev.manual !== undefined) {
        for (const entry of ev.manual) {
          newEntry = { time: entry.time };
          try {
            if (entry.cmd === "login") {
              newEntry = {
                type: "admin",
                title: "Login",
                specifictitle: entry.user.username,
                icon: "mdi-login",
                color: "blue-grey lighten-2",
                ...newEntry,
              };
            }
            if (entry.cmd === "reboot") {
              newEntry = {
                type: "system",
                title: "Reboot",
                details: "(" + entry.user + " @ " + entry.ip + ")",
                icon: "mdi-restart",
                color: "purple accent-2",
                ...newEntry,
              };
            }
            if (entry.cmd === "engage") {
              detailText = "Manual Engage";
              if (entry.type === "revert") {
                detailText = "Manual Revert";
              }
              newEntry = {
                type: "interlock",
                title: entry.tool,
                details:
                  detailText +
                  " " +
                  String(entry.tool) +
                  " (" +
                  entry.user +
                  " @ " +
                  entry.ip +
                  ")",
                icon: "mdi-hand",
                color: "green lighten-1",
                ...newEntry,
              };
              if (entry.type === "revert") {
                newEntry.icon = "mdi-check";
                newEntry.color = "amber lighten-2";
              }
            }
            if (entry.cmd === "disengage") {
              detailText = "Manual Disengage";
              if (entry.type === "revert") {
                detailText = "Manual Revert";
              }
              newEntry = {
                type: "interlock",
                title: entry.tool,
                details:
                  detailText +
                  " " +
                  String(entry.tool) +
                  " (" +
                  entry.user +
                  " @ " +
                  entry.ip +
                  ")",
                icon: "mdi-hand",
                color: "red lighten-1",
                ...newEntry,
              };
              if (entry.type === "revert") {
                newEntry.icon = "mdi-minus";
                newEntry.color = "amber lighten-2";
                //newEntry.color = "blue-grey lighten-2"
              }
            }
            if (entry.cmd === "on") {
              newEntry = {
                type: "relays",
                title: "ON",
                specifictitle: entry.tool,
                details: "(" + entry.user + " @ " + entry.ip + ")",
                icon: "mdi-electric-switch-closed",
                color: "green lighten-1",
                ...newEntry,
              };
            }
            if (entry.cmd === "off") {
              newEntry = {
                type: "relays",
                title: "OFF",
                specifictitle: entry.tool,
                details: "(" + entry.user + " @ " + entry.ip + ")",
                icon: "mdi-electric-switch",
                color: "red lighten-1",
                ...newEntry,
              };
            }
            if (entry.cmd === "restore") {
              newEntry = {
                type: "relays",
                title: "Restore Relays",
                details: "(" + entry.user + " @ " + entry.ip + ")",
                icon: "mdi-backup-restore",
                color: "blue-grey lighten-2",
                ...newEntry,
              };
            }
            newEventList.push(newEntry);
          } catch {
            console.log("Error");
          }
        }
      }
      if (ev.coral !== undefined) {
        for (const entry of ev.coral) {          
          newEntry = { time: entry.time };
          try {
            if (entry.cmd === "enable") {
              detailText = "Coral Engage";
              newEntry = {
                type: "coral",
                title: entry.tool,
                details:
                  detailText + " (" + entry.ip + ")",
                icon: "mdi-check",
                color: "blue-grey lighten-2",
                ...newEntry,
              };
            }
            if (entry.cmd === "disable") {
              detailText = "Coral Disengage";
              newEntry = {
                type: "coral",
                title: entry.tool,
                details:
                  detailText + " (" + entry.ip + ")",
                icon: "mdi-minus",
                color: "blue-grey lighten-2",
                ...newEntry,
              };
            }
            newEventList.push(newEntry);
          } catch {
            console.log("Error");
          }
        }
      }
      return newEventList.sort(function cmp(e1, e2) {
        return e2.time - e1.time;
      });
    },
    getEventDescription(item) {
      var s = item.details;
      return s;
    },
    getEventTitle(item) {
      var s = moment(item.time).format("MMM DD, hh:mmA") + ": " + item.title;
      if (item.specifictitle !== undefined) {
        s = s + " (" + item.specifictitle + ")";
      }
      return s;
    },
  },
};
</script>