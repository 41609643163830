<template>
  <v-card color="primary" class="ma-auto" outlined width="500" max-width="500">
    <v-card-title class="white--text"
      >Relay Status
      <v-spacer></v-spacer>
      <v-btn color="white" icon @click="refreshCard()">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </v-card-title>

    <v-list>
      <v-subheader>
        Summary of which relays are valid and their current on/off status. Relay
        overrides only last until the next interlock command.
      </v-subheader>
      <v-divider></v-divider>
      <v-subheader>AC Relays</v-subheader>

      <template v-for="(item, i) in actionList.ac">
        <v-list-item :key="i">
          <v-list-item-avatar>
            <v-icon :class="item.color" dark>
              {{ item.icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="getDescription(item)"
            ></v-list-item-title>
            <v-list-item-subtitle v-text="item.detail"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              v-if="item.canActivate"
              :loading="item.loading"
              :disabled="item.loading"
              color="primary"
              @click="buttonRelay(item)"
            >
              {{ item.buttonText }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-divider></v-divider>
      <v-subheader>DC Relays</v-subheader>
      <template v-for="(item, i) in actionList.dc">
        <v-list-item :key="i + 10000">
          <v-list-item-avatar>
            <v-icon :class="item.color" dark>
              {{ item.icon }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              v-text="getDescription(item)"
            ></v-list-item-title>
            <v-list-item-subtitle v-text="item.detail"></v-list-item-subtitle>
            <v-list-item-subtitle
              v-text="getOverrideText(item)"
            ></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              v-if="item.canActivate"
              :loading="item.loading"
              :disabled="item.loading"
              color="primary"
              @click="buttonRelay(item)"
            >
              {{ item.buttonText }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <template
        v-if="this.$store.getters['user/permissions'].includes('relays')"
      >
        <v-divider></v-divider>
        <v-list-item key="reset">
          <v-list-item-content>
            <v-list-item-title
              >Restore relays to interlock state</v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              color="primary"
              :loading="restoreloading"
              :disabled="restoreloading"
              @click="buttonRestore()"
              >Restore All</v-btn
            >
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

<script>
//var moment = require("moment");
import dataloader from "@/api/dataloader.js";
var moment = require("moment");
export default {
  data: () => ({
    stateTimeout: 1.0,
    restoreloading: false,
  }),

  props: {
    selectionGroup: String,
  },

  methods: {
    refreshCard() {
      this.$store.dispatch("data/refreshState");
    },
    getDescription(item) {
      var s = item.channel + " -- " + item.text;
      return s;
    },
    getOverrideText(item) {
      return item.overrideText;
    },
    isEngaged(item) {
      return item.value == 1;
    },
    buttonRelay(item) {
      item.loading = true;
      // call the API
      console.log("CALL API BUTTON");
      console.log(JSON.stringify(item));
      console.log(item.value);
      if (item.value == 0) {
        dataloader.setRelay(item.channel, "on");
      } else {
        dataloader.setRelay(item.channel, "off");
      }
      setTimeout(() => {
        this.$store.dispatch("data/refreshState");
        item.loading = false;
      }, this.stateTimeout * 1000);
    },
    buttonRestore() {
      this.restoreloading = true;
      setTimeout(() => {
        this.$store.dispatch("data/refreshState");
        this.restoreloading = false;
      }, this.stateTimeout * 1000);

      dataloader.restoreAll();
    },
  },

  asyncComputed: {
    actionList: {
      async get() {
        // get the setup data
        console.log("relay action list");
        const state = this.$store.getters["data/getDataList"]("state").data;
        console.log("STATE: " + JSON.stringify(state));
        var hardwareSetup = state.hardware;
        var relayState = state.state.actions;
        var relayOverride = state.state.override.byrelay;
        var permissions = this.$store.getters["user/permissions"];
        var results = { dc: [], ac: [] };
        for (const [relayName] of Object.entries(hardwareSetup.relays)) {
          var relayAvailable = relayState[relayName] !== undefined;
          var entry = {
            color: "blue-grey lighten-4",
            icon: "mdi-close",
            text: "not in use",
            loading: false,
            channel: relayName,
            isvalid: relayAvailable,
            canActivate: false,
          };
          if (relayState[relayName] !== undefined) {
            entry.canActivate = permissions.includes("relays");
            if (relayState[relayName] === "on") {
              entry.icon = "mdi-electric-switch-closed";
              entry.color = "green lighten-1";
              entry.text = "closed";
              entry.buttonText = "open";
              entry.value = 1;
            } else {
              entry.icon = "mdi-electric-switch";
              entry.color = "red lighten-1";
              entry.text = "open";
              entry.buttonText = "close";
              entry.value = 0;
            }
            if (
              relayOverride !== undefined &&
              relayOverride[relayName] !== undefined
            ) {
              entry.overrideText =
                "manual override: " +
                moment(relayOverride[relayName].action).format("MMM DD HH:mm");
            }
          }
          if (relayName.startsWith("dc")) results.dc.push(entry);
          else results.ac.push(entry);
        }
        console.log("relay action list done");
        return results;
      },
      default: [],
    },
  },
};
</script>