<template>
  <v-container fluid class="ml-1">
    <template v-if="$store.getters['user/isLoggedIn']">
      <v-row>
        <draggable class="row" v-model="draggableCards">
          <template v-for="(item, i) in draggableCards">
            <v-col v-if="item.type === 'plot'" cols="auto" :key="i">
              <PlotData showlist="item.showlist" />
            </v-col>
            <v-col v-else-if="item.type === 'events'" cols="auto" :key="i">
              <EventViewer />
            </v-col>
            <v-col v-else-if="item.type === 'data'" cols="auto" :key="i">
              <DataList />
            </v-col>
            <v-col v-else-if="item.type === 'feedback'" cols="auto" :key="i">
              <FeedbackStatus />              
            </v-col>
            <v-col v-else-if="item.type === 'toolview'" cols="auto" :key="i">
              <ToolView />
            </v-col>
            <v-col v-else-if="item.type === 'actions'" cols="auto" :key="i">
              <ActionButtons
                v-on:myEvent="console.log('event!!')"
                selectionGroup="test123"
              />
            </v-col>
            <v-col v-else-if="item.type === 'coral'" cols="auto" :key="i">
              <CoralStatus />
            </v-col>
            <v-col v-else-if="item.type === 'relays'" cols="auto" :key="i">
              <RelayStatus />
            </v-col>
            <v-col v-else-if="item.type === 'tools'" cols="auto" :key="i">
              <SelectionList
                v-on:myEvent="console.log('event!!')"
                v-on:selectEvent="console.log('selection!!')"
                selectionGroup="pecvd"
              />
            </v-col>
          </template>
        </draggable>
      </v-row>
    </template>
    <template v-if="!$store.getters['user/isLoggedIn']">
      <v-row>
        <v-col cols="12"> <Header /> </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <UserLogin />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>


<script>
import EventViewer from "@/components/dataview/EventViewer";
import CoralStatus from "@/components/dataview/CoralStatus";
import RelayStatus from "@/components/dataview/RelayStatus";
import PlotData from "@/components/dataview/PlotData";
import FeedbackStatus from "@/components/dataview/FeedbackStatus";
import UserLogin from "@/components/user/UserLogin";
import Header from "@/components/Header";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
    EventViewer,
    CoralStatus,
    RelayStatus,
    PlotData,
    FeedbackStatus,
    Header,
    UserLogin,
  },

  data: () => ({
    cardsToShowUpdateCounter: 0,
    cardListName: "home",
  }),

  computed: {
    draggableCards: {
      get() {
        this.cardsToShowUpdateCounter; // dummy to make sure the value updates after set as it's not listening to the store
        return this.$store.getters["cards/getCardList"](this.cardListName).data;
      },
      set(val) {
        // set data, but also trigger the reload of the show cards
        this.cardsToShowUpdateCounter++;
        this.$store.commit("cards/setCardList", {
          name: this.cardListName,
          data: val,
        });
      },
    },
  },
};
</script> 
